<template>
  <div class="rounded-lg shadow-lg">
    <form-component
      :customer="customer"
      mode="edit" class="pt-1" />
  </div>
</template>

<script>
import FormComponent from "./components/FormComponent.vue";
export default {
  components: {
    FormComponent,
  },
  data() {
    return {
      company: {},
      customer: {
        contacts: [
          {
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            designation_id: "",
          }
        ],
        contacts: [
          {
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            designation_id: "",
            type: "financial",
          }
        ]
      },
    };
  },
  created() {
    this.getCustomer();
  },
  methods: {
    getCustomer() {
      this.$store
        .dispatch("customer/getCustomer", this.$route.params.id)
        .then((response) => {
          this.customer = response.data;
          this.customer.primary_id = response.data.customer.id;
          this.customer.id = response.data.id;
          this.company = response.data.company;
          if (this.customer.customer.contacts.length < 1) {
            this.customer.customer.contacts.push({
              first_name: "",
              last_name: "",
              email: "",
              phone: "",
              designation_id: "",
            });
          }
          if (this.customer.customer.financial.length < 1) {
            this.customer.customer.financial.push({
              first_name: "",
              last_name: "",
              email: "",
              phone: "",
              designation_id: "",
              type: "financial",
            });
          }
        });
    },
  },
};
</script>

<style scoped>

</style>
